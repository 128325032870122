import axios from "axios";
import { URL } from "../utils/globalConect";

export const setError = (payload) => ({
  type: "SET_ERROR",
  payload,
});

export const saveFacturaRequest = (payload) => ({
  type: "SAVEFACTURA_REQUEST",
  payload,
});

export const fetchFacturasRequest = (payload) => ({
  type: "FETCHFACTURAS_REQUEST",
  payload,
});

export const FacturasGrupRequesr = (payload) => ({
  type: "FACTURASGRUP_REQUEST",
  payload,
});

export const statusFacturaRequest = (payload) => ({
  type: "STATUSFACTURA_REQUEST",
  payload,
});

export const getFacturaRequest = (payload) => ({
  type: "GETFACTURAREQUEST",
  payload,
});

export const findEmbarqueRequest = (payload) => ({
  type: "FINDEMBARQUEREQUEST",
  payload,
});

export const clearEmbarqueRequest = (payload) => ({
  type: "CLEAREMBARQUEREQUEST",
  payload,
});

export const clearFactura = (payload) => ({
  type: "CLEARFACTURA",
  payload,
});

export const fetchFacturas = (section, props) => {
  return (dispatch) => {
    axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchFacturasRequest(data.facturas));
      })
      .catch(({ error }) => {});
  };
};

export const fetchFacturasGrup = (section, props) => {
  return (dispatch) => {
    axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        dispatch(FacturasGrupRequesr(data.pendientes));
      })
      .catch(({ error }) => {});
  };
};

export const saveFactura = (payload, section, props) => {
  return async (dispatch) => {
    return axios
      .post(
        `${URL}${section}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(({ data }) => {
        dispatch(saveFacturaRequest(data.factura));
      })
      .catch((error) => {
        throw new Error([error][0].response.data);
      });
  };
};

export const viewRfcFacturas = (rfc, typeview, section, props) => {
  return (dispatch) => {
    axios
      .get(`${URL}${section}/${rfc}/${typeview}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        dispatch(fetchFacturasRequest(data.facturas));
        props.history.push("/fdeetals");
      })
      .catch(({ error }) => {
        window.alert([error][0].response.message);
      });
  };
};

export const sendStatusFactura = (payload, section, props) => {
  return async (dispatch) => {
    return axios
      .put(
        `${URL}${section}/${props.id}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(() => {
        dispatch(statusFacturaRequest(props.id));
        return true;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getFactura = (payload, section, props) => {
  return async (dispatch) => {
    return axios
      .get(`${URL}${section}/${payload}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        dispatch(getFacturaRequest(data));
        return data;
      })
      .catch((error) => {
        throw new Error([error][0].response.data);
      });
  };
};

export const findEmbarque = (section, props) => {
  return async () => {
    return axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        return data.response;
      })
      .catch((_) => {});
  };
};

export const getTipoCambio = (section, props) => {
  return async () => {
    return axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        return data.response;
      })
      .catch((_) => {});
  };
};

/**
 * Acepta la recepcion de la factura
 *
 * @param {"sin este no pasa"} payload
 * @param {"id de la favtura que se esta aprovando"} idFac
 * @param {"sufijo del URL API"} section
 * @param {"porps para desglosar el token"} props
 */
export const acceptFactura = (payload, idFac, section, props) => {
  return async (dispatch) => {
    return axios
      .put(
        `${URL}${section}/${idFac}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(() => {
        dispatch(statusFacturaRequest(idFac));
        return true;
      })
      .catch((error) => {
        return error;
      });
  };
};

/**
 * Rechaza las facturas
 *
 * @param {"Cuerpo del Mensaje"} payload
 * @param {"id de la favtura que se esta rechazando"} idFac
 * @param {"sufijo del URL API"} section
 * @param {"porps para desglosar el token"} props
 */
export const rejectFactura = (payload, idFac, section, props) => {
  return async (dispatch) => {
    return axios
      .put(
        `${URL}${section}/${idFac}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(() => {
        dispatch(statusFacturaRequest(idFac));
        return true;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const applyPago = (data, section, props) => {
  return async () => {
    return axios
      .put(
        `${URL}${section}`,
        `json=${encodeURIComponent(JSON.stringify(data))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getTerprofit = (section, props) => {
  return (dispatch) => {
    return axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        if (Array.isArray(data.response)) {
          return data.response.forEach((item) => {
            dispatch(applyConcepto(item));
          });
        }
        return dispatch(applyConcepto(data.response));
      })
      .catch((_) => {});
  };
};

export const fetchProgramado = (section, props) => {
  return async () => {
    return axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        return data.facturas;
      })
      .catch(({ error }) => {});
  };
};

export const programarFactura = (section, id, props) => {
  return async (dispatch) => {
    return axios
      .get(`${URL}${section}/${id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(() => {
        dispatch(statusFacturaRequest(id));
        return true;
      })
      .catch(({ error }) => {});
  };
};

export const sendUpdateFactura = (payload, section, id, props) => {
  return async () => {
    return axios
      .put(
        `${URL}${section}/${id}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  };
};

export const applyConcepto = (payload) => ({
  type: "ADD_CONCEPTO",
  payload,
});

export const setFilters = (payload) => ({
  type: "SETFILTERS",
  payload,
});
