const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        session: {
          token: action.payload.token,
          user: action.payload.user,
          exp: action.payload.exp,
          lng: action.payload.lng
        },
        backErrors: [],
      };
      case "SETLNG_REQUEST":
        return {
          ...state,
          session:{
            ...state.session,
            lng: action.payload
          }
        };
  
    case "LOGOUT_REQUEST":
      let clng = state.session.lng;
      state = null;
      return {
        ...state,
        session: { token: {}, user: {}, exp: 0,  lng:clng},
        users: [],
        facturaGrup: [],
        facturas: [],
        factura: {},
        backErrors: [],
        profit: {
          data: [],
          subTotal: 0,
          total: 0,
        },
        searchForm:{}
      };

    case "FETCHUSERS_REQUEST":
      return {
        ...state,
        users: action.payload,
      };

    case "CANCELEDITUSER_REQUEST":
      return {
        ...state,
        providers: undefined,
      };

    case "FETCHPROVIDERS_REQUEST":
      return {
        ...state,
        providers: action.payload,
      };

    case "SAVEUSER_REQUEST":
      return {
        ...state,
        users: state.users.concat(action.payload),
        providers: undefined,
      };

    case "UPDATEUSER_REQUEST":
      return {
        ...state,
        users: state.users.map(
          (user) => action.payload.find((nuser) => nuser.id === user.id) || user
        ),
        providers: undefined,
      };

    case "DELETEUSER_REQUEST":
      return {
        ...state,
        users: state.users.filter(
          (user) => Number(user.id) !== Number(action.payload)
        ),
      };

    case "SAVEFACTURA_REQUEST":
      return {
        ...state,
        facturas: state.facturas.concat(action.payload),
      };

    case "FETCHFACTURAS_REQUEST":
      return {
        ...state,
        facturas: action.payload,
      };

    case "FACTURASGRUP_REQUEST":
      return {
        ...state,
        facturaGrup: action.payload,
      };

    case "STATUSFACTURA_REQUEST":
      return {
        ...state,
        facturas: state.facturas.filter(
          (factura) => Number(factura.id) !== Number(action.payload)
        ),
      };

    case "GETFACTURAREQUEST":
      return {
        ...state,
        factura: action.payload,
      };

    case "FINDEMBARQUEREQUEST":
      return {
        ...state,
        embarque: action.payload,
      };

    case "CLEAREMBARQUEREQUEST":
      return {
        ...state,
        embarque: undefined,
      };

    case "ADD_CONCEPTO":
      if (state.profit.data.indexOf(action.payload) === -1) {
        return {
          ...state,
          profit: {
            data: state.profit.data.concat(action.payload),
            subTotal:(Number(state.profit.subTotal) + Number(action.payload.monto)).toFixed(2),
            total:(Number(state.profit.total) + Number(action.payload.total)).toFixed(2),
          },
        }
      }else{
        return {
          ...state
        }
      };

    case "DELETE_CONCEPTO":
      let resub = Number(action.payload.monto);
      let restot = Number(action.payload.total);
      return {
        ...state,
        profit: {
          data: state.profit.data.filter(
            (item) => item.idProfit !== action.payload.idProfit
          ),
          subTotal: Number(state.profit.subTotal - resub).toFixed(2),
          total: Number(state.profit.total - restot).toFixed(2),
        },
      };

    case "CLEARFACTURA":
      return {
        ...state,
        profit: {
          data: [],
          subTotal: 0,
          total: 0,
        },
      };

    case "SET_ERROR":
      return {
        ...state,
        backErrors: action.payload,
      };

    case "DELETEARCHIVO":
      return {
        ...state,
        factura: {
          ...state.factura,
          Factura: {
            ...state.factura.Factura,
            archivos: state.factura.Factura.archivos.filter(
              (item) => item.id !== action.payload
            ),
          },
        },
      };
    case "ADDARCHIVO":
      return {
        ...state,
        factura: {
          ...state.factura,
          Factura: {
            ...state.factura.Factura,
            archivos: state.factura.Factura.archivos.concat(action.payload),
          },
        },
      };

    case "SETFILTERS":
      return {
        ...state,
        searchForm: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
