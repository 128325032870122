import React, { useState } from "react";
import axios from "axios";
import { URL } from "../utils/globalConect";
import { connect } from "react-redux";

const ConceptosFactura = (props) => {
  const [conceptos, setConceptos] = useState({});
  const [isLoading, setloadin] = useState(false);

  useState(() => {
    setloadin(true);
    axios
      .get(`${URL}cw/readCfdi?documentID=${props.documentId}`, {
        Authorization: props.token,
      })
      .then(({ data }) => {
        setConceptos(data);
      })
      .catch(() => {});
      setloadin(false);
  });

  return (
    <>
      {isLoading === true ? (
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <table className="table table-sm">
          <thead className="titulotabla-sm">
            <tr>
              <th className="text-left">Folio</th>
              <th className="text-left">Cliente</th>
              <th className="text-left">F-Factura</th>
              <th className="text-left">Total</th>
            </tr>
          </thead>
          <tbody>
            {conceptos["Comprobante"] && (
              <tr>
                <td className="td-sm">
                  {conceptos["Comprobante"]["@attributes"]["Folio"]}
                </td>
                <td className="td-sm">
                  {conceptos["Receptor"]["@attributes"]["Nombre"]}
                </td>
                <td className="td-sm">
                  {conceptos["Comprobante"]["@attributes"]["Fecha"]}
                </td>
                <td className="td-sm">
                  ${conceptos["Comprobante"]["@attributes"]["Total"]}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    token: state.session.token,
  };
};

export default connect(mapStateToprops, null)(ConceptosFactura);
