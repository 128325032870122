import React, { useState } from "react";
import { connect } from "react-redux";
import { URL } from "../utils/globalConect";
import axios from "axios";
import Swal from "sweetalert2";
import { delArchivo, addArchivo } from "../actions/archivos";
import { DragAndDropSingle } from "../utils/dragAndDrop";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { Toggle, Modal } from "../utils/modalUtil.jsx";

import "../assets/styles/components/TablePageSM.css";
import UploadSelecDialog from "../components/UploadSelecDialog";


const DocumentList = (props) => {
  const [t] = useTranslation("takenXML");
  const [isLoading, setLoading] = useState(false);
  const MySwal = withReactContent(Swal);
  const MyToast = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );
  const borraArchivo = (id) => {
    MySwal.fire({
      title: "Esta seguro?",
      text: "Este proceso no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar!",
    }).then((result) => {
      if (result.value) {
        props.delArchivo("delar", props, id);
      }
    });
  };

  const handleCarga = (resBack) => {
    setLoading(true);
    let exist = props.documentos.find(
      (x) => x.extension === resBack.extension && resBack.extension === "xml"
    );
    if (exist !== undefined) {
      MyToast.fire({
        icon: "error",
        title: "No es posible cargar mas de un XML",
      });
      setLoading(false);
    } else {
      resBack.idFactura = props.idFactura;
      resBack.idFProveedor = props.idFProveedor;
      props
        .addArchivo("upar", props, resBack)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {});
    }
  };

  const handledownload = (item) => {
    axios
      .get(`${URL}dowd/${item.id}`, {
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
        responseType: "blob",
      })
      .then((response) => {
        let blob = new Blob([response.data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.name;
        link.click();
      });
  };


  return (
    <>
      <div className="row">
        <div className="col col-12">
          <div className="row">
            <div className=" col col-10">
              <h3>{t("documentos.archivos")}</h3>
            </div>
            <div className="col col-2">
              {props.session.user.role !== "USER" && (
                <DragAndDropSingle handleDrop={handleCarga} transaprent={true}>
                  {!isLoading ? (
                    <button className="btn btn-link btnAdd">
                      <i className="fas fa-plus" style={{ color: "white" }}></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-link btnAdd"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </button>
                  )}
                </DragAndDropSingle>
              )}
            </div>
          </div>
          <div className="row tabla-sm-doc">
            <table className="table table-sm">
              <thead className="titulotabla-sm">
                <tr>
                  <th>{t("documentos.archvosEnviados")}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.documentos.map((item, i) => (
                  <tr key={i}>
                    <td className="td-sm">{item.name}</td>
                    <td>
                      <button
                        onClick={() => handledownload(item)}
                        className="btn btn-link"
                      >
                        <i
                          className="fas fa-download"
                          style={{ color: "blue" }}
                        ></i>
                      </button>
                    </td>
                    <td>
                      {props.session.user.role !== "USER" ? (
                        <Toggle
                          toggle={(show) => (
                            <button onClick={show} className="btn btn-link">
                              <i
                                className="fas fa-upload"
                                style={{ color: "green" }}
                              />
                            </button>
                          )}
                          content={(hide) => (
                            <Modal>
                              <UploadSelecDialog
                                handelHide={hide}
                                docuentId={item.id}
                              />
                            </Modal>
                          )}
                        />
                      ) : null}
                    </td>
                    <td>
                      {item.extension.toLowerCase() !== "xml" &&
                      props.session.user.role !== "USER" ? (
                        <button
                          onClick={() => borraArchivo(item.id)}
                          className="btn btn-link"
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    referencia: state.factura.Factura.referenciaReal,
  };
};

const mapDispathToProps = {
  delArchivo,
  addArchivo,
};

export default connect(mapStateToprops, mapDispathToProps)(DocumentList);
