import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import thunk from "redux-thunk";
import reducer from "./reducers";

import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import login_es from "./translations/es/login.json";
import login_en from "./translations/en/login.json";
import facturaStore_es from "./translations/es/facturaStore.json";
import facturaStore_en from "./translations/en/facturaStore.json";
import facturaFetch_es from "./translations/es/facturaFetch.json";
import facturaFetch_en from "./translations/en/facturaFetch.json";
import takenXML_es from "./translations/es/takenXML.json";
import takenXML_en from "./translations/en/takenXML.json";

import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import App from "../src/routes/App";
import * as serviceWorker from "./serviceWorker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  session: { token: {}, user: {}, exp: 0, lng: "es" },
  users: [],
  facturaGrup: [],
  facturas: [],
  factura: {},
  archivos: [],
  embarque: {},
  backErrors: [],
  profit: {
    meta: [],
    data: [],
  },
  searchForm: {},
};

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {}
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return initialState;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

const store = createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

i18next.init({
  interpolation: { escapeValue: false },
  lng: persistedState.session.lng,
  resources: {
    es: {
      global: global_es,
      login: login_es,
      facturaStore: facturaStore_es,
      facturaFetch: facturaFetch_es,
      takenXML: takenXML_es,
    },
    en: {
      global: global_en,
      login: login_en,
      facturaStore: facturaStore_en,
      facturaFetch: facturaFetch_en,
      takenXML: takenXML_en,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
