import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { fetchUsers, deleteUser } from "../actions/usuarios";

import { Toggle, Modal } from "../utils/modalUtil.jsx";

import gravatar from "../utils/gravatar";
import Usuario from "../components/Usuario";

import "../assets/styles/components/TablePage.css";
import "../assets/styles/Filtrado.css";

const Usuarios = props => {
  useState(() => props.fetchUsers("users", props.session.token));

  const [searchForm, setInput] = useState("");
  const [filtrados, setFilter] = useState(null);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setFilter(props.users);
  }, [props.users]);

  const handleFinderChange = event => {
    setInput(event.target.value.toLowerCase());
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (searchForm) {
      setFilter(
        props.users.filter(
          i =>
            i.name.toLowerCase().includes(searchForm) ||
            i.email.toLowerCase().includes(searchForm)
        )
      );
    } else {
      setFilter(props.users);
    }
  };

  const delUser = id => {
    MySwal.fire({
      title: "Esta seguro?",
      text: "Este proceso no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar!"
    }).then(result => {
      if (result.value) {
        props.deleteUser("user", props, id);
      }
    });
  };

  return (
    <div className="principal">
      <div className="ext-center filter">
        <h1>Cuentas de usuarios</h1>
        <div className="d-flex justify-content-between">
          <form
            onSubmit={handleSubmit}
            className="d-flex justify-content-center"
          >
            <div>
              <input
                id="date"
                name="filterT"
                type="text"
                onChange={handleFinderChange}
                value={searchForm}
                className="form-control"
                placeholder="Usuario / Email"
                aria-label="Recipient's username"
              />
            </div>
            <div>
              <button className="btn btn-info goFilter" type="submit">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
          <div className="btnAdd">
            <Toggle
              toggle={show => (
                <button className="btn btn-link" onClick={show}>
                  <i className="fas fa-plus"></i>
                </button>
              )}
              content={hide => (
                <Modal>
                  <Usuario handelHide={hide} action={"N"} />
                </Modal>
              )}
            />
          </div>
        </div>
      </div>
      <div className="tablagen">
        {filtrados && filtrados.length > 0 && (
          <table style={{ width: "100%" }}>
            <thead>
              <tr className="titulotabla">
                <th></th>
                <th>Nombre</th>
                <th>Email</th>
                <th className="prioridad1">RFC</th>
                <th className="prioridad1">Rol</th>
                <th className="prioridad1">Usuario</th>
                <th>Contraseña</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtrados.map(item => (
                <tr className="contenido" key={item.id}>
                  <td>
                    <img
                      src={gravatar(item.email)}
                      alt="Avatar"
                      className="avatar"
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td className="prioridad1">{item.RFC}</td>
                  <td className="prioridad1">{item.role}</td>
                  <td className="prioridad1">{item.username}</td>
                  <td>{item.dpassword}</td>
                  <td>
                    <Toggle
                      toggle={show => (
                        <button className="btn btn-link" onClick={show}>
                          <i className="far fa-eye"></i>
                        </button>
                      )}
                      content={hide => (
                        <Modal>
                          <Usuario handelHide={hide} action={"E"} {...item} />
                        </Modal>
                      )}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-link"
                      onClick={() => delUser(item.id)}
                    >
                      <i className="fas fa-trash" style={{ color: "red" }}></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const mapStateToprops = state => {
  return {
    session: state.session,
    users: state.users
  };
};

const mapDispathToProps = {
  fetchUsers,
  deleteUser
};

export default connect(mapStateToprops, mapDispathToProps)(Usuarios);
