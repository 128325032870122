import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { fetchFacturas } from "../actions/facturas";
import { Toggle, Modal } from "../utils/modalUtil.jsx";
import { useTranslation } from "react-i18next";
import FacturaRecord from "../components/FacturaRecord";
import NoData from "../components/NoData";

import "../assets/styles/components/TablePage.css";
import FacturaViewProv from "../components/FacturaViewProv";

const FetchFacturas = (props) => {
  const [t] = useTranslation("facturaFetch");
  //------> Cargando las facturas desde base de datos
  useState(() => props.fetchFacturas("facpen", props));

  //------> Definiendo los campos que se utilizaran en el form de filtros
  const [searchForm, setInput] = useState({
    busqueda: "",
    status: "pendiente",
  });

  //------> definiendo el arreglo que tomara las facturas del state
  const [filtrados, setFilter] = useState(null);

  //------> cargando las facturas al arreglo filtrados cada que exista un cambio
  useEffect(() => {
    setFilter(props.facturas);
  }, [props.facturas]);

  //------> resciviendo la informacion tecleada en los campos de filtro
  const handleFinderChange = (event) => {
    setInput({
      ...searchForm,
      [event.target.name]: event.target.value.toLowerCase(),
    });
  };

  //------> ejecutando la funcion de filtrar
  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchForm.busqueda) {
      setFilter(
        props.facturas.filter(
          (i) =>
            i.estatus.toLowerCase() === searchForm.status &&
            i.folio.toLowerCase() === searchForm.busqueda
        )
      );
    } else {
      setFilter(
        props.facturas.filter(
          (i) => i.estatus.toLowerCase() === searchForm.status
        )
      );
    }
  };

  // renderizando icono de status
  const iconRender = (status, id) => {
    switch (status) {
      case (status = "PENDIENTE"):
        return <i className="fas fa-minus" style={{ color: "Gold" }}></i>;
      case (status = "RECIBIDO"):
        return <i className="fas fa-check" style={{ color: "SeaGreen" }}></i>;
      case (status = "RECHAZADO"):
        return <i className="fas fa-times" style={{ color: "Red" }}></i>;
      case (status = "PAGADO"):
        return (
          <i className="fas fa-dollar-sign" style={{ color: "Green" }}></i>
        );
      default:
        return null;
    }
  };

  return (
    <div className="principal">
      <div className="text-center filter">
        <h1>{t("titulo.estadoCuenta")}</h1>
        <div className="d-flex justify-content-between">
          <form
            className="d-flex justify-content-center"
            onSubmit={handleSubmit}
          >
            <div>
              <input
                id="date"
                name="busqueda"
                type="text"
                value={searchForm.name}
                onChange={handleFinderChange}
                className="form-control"
                placeholder={t("busqueda.folioFechaFactura")}
                aria-label="Recipient's username"
              />
            </div>
            <div>
              <select
                name="status"
                value={searchForm.name}
                onChange={handleFinderChange}
                className="form-control"
                aria-label="Recipient's username"
              >
                <option value="pendiente">{t("busqueda.pendiente")}</option>
                <option value="recibido">{t("busqueda.recibido")}</option>
                <option value="pagado">{t("busqueda.pagado")}</option>
                <option value="rechazado">{t("busqueda.rechazado")}</option>
              </select>
            </div>
            <div>
              <button className="btn btn-info goFilter" type="submit">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </form>
          <div className="btnAdd">
            <Toggle
              toggle={(show) => (
                <button className="btn btn-link" onClick={show}>
                  <i className="fas fa-plus"></i>
                </button>
              )}
              content={(hide) => (
                <Modal>
                  <FacturaRecord
                    handelHide={hide}
                    handeleClose={() => props.fetchFacturas("facpen", props)}
                  />
                </Modal>
              )}
            />
          </div>
        </div>
      </div>
      <div className="tablagen">
        {filtrados && filtrados.length > 0 ? (
          <table style={{ width: "100%" }}>
            <thead>
              <tr className="titulotabla">
                <th>{t("tabla.folio")}</th>
                <th>{t("tabla.fechaFact")}</th>
                <th>{t("tabla.fechaCarga")}</th>
                <th>{t("tabla.moneda")}</th>
                <th>{t("tabla.subtotal")}</th>
                <th>{t("tabla.iva")}</th>
                <th>{t("tabla.retencion")}</th>
                <th>{t("tabla.total")}</th>
                <th>{t("tabla.status")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtrados.map((item) => (
                <tr className="contenido" key={item.id}>
                  <td>{item.folio}</td>
                  <td>{item.fechaFactura}</td>
                  <td>{item.created_at}</td>
                  <td>{item.moneda}</td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.subTotal}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.iva}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.retencion}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={item.total}
                    />
                  </td>
                  <td>{iconRender(item.estatus, item.id)}</td>
                  <td>
                    <Toggle
                      toggle={(show) => (
                        <button className="btn btn-link" onClick={show}>
                          <i className="far fa-eye"></i>
                        </button>
                      )}
                      content={(hide) => (
                        <Modal>
                          <FacturaViewProv
                            handelHide={hide}
                            idFactura={item.id}
                          />
                        </Modal>
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    facturas: state.facturas,
  };
};

const mapDispathToProps = {
  fetchFacturas,
};

export default connect(mapStateToprops, mapDispathToProps)(FetchFacturas);
