import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { URL } from "../utils/globalConect";
import axios from "axios";


const UploadSelecDialog = (props) => {
  const [form, setValues] = useState({
    referencia: "",
  });
  const [isLoad, setLoad] = useState(false);
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    setValid(!form.referencia);
  }, [form, isValid]);

  const handleChange = (event) => {
    setValues({
      ...form,
      referencia: event.target.value,
    });
  };

  const handledupownload = ()=>{
    setLoad(true);
    axios
    .get(`${URL}cw/upDoc?shipment=${form.referencia}&fileId=${props.docuentId}`, {
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: props.token,
      },
    }).then(()=>{
      cbHide();
    });
    setLoad(false);
  }

  const cbHide = () => {
    props.handelHide();
  };

  return (
    <div className="modalprincipal">
      <div className="submodal">
        <div className="submodalTitle">
          <span>SUBIR ARCHIVO</span>
        </div>
      </div>
      <div className="modalcontenido">
        <div className="row justify-content-center">
          <div className="col-12">
            <label htmlFor="re">Seleccione una referencia:</label>
            <select
              name="referencia"
              type="text"
              className="form-control"
              value={form.referencia || ""}
              onChange={handleChange}
            >
              <option value=""></option>
              {props.referencias.map((item, i)=><option key={i} value={item}>{item}</option>)}
            </select>
          </div>
        </div>
        <div className="row botonmodal">
          <div className="col-6">
            <button
              className=" btn btn-link cancelar"
              type="button"
              onClick={cbHide}
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            {isLoad ? (
              <button className="btn btn-link aceptar" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Subiendo Archivo...
              </button>
            ) : (
              <button
                className="btn btn-link aceptar"
                type="button"
                onClick={handledupownload}
                disabled={isValid}
              >
                Aceptar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    referencias:  [...new Set(state.profit.data.map((x) => x.referencia))],
    token: state.session.token
  };
};
const mapDispathToProps = {
};

export default connect(mapStateToprops, mapDispathToProps)(UploadSelecDialog);
