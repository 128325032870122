import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { URL } from "../utils/globalConect";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTagInput from "@pathofdev/react-tag-input";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  saveFacturaRequest,
  clearEmbarqueRequest,
  setError,
} from "../actions/facturas";
import { fetchProviders } from "../actions/usuarios";
import { useTranslation } from "react-i18next";

import { DragAndDropList, DragAndDropSingle } from "../utils/dragAndDrop";

import "../assets/styles/components/FormModal.css";
import "@pathofdev/react-tag-input/build/index.css";

const FacturaRecord = (props) => {
  const initialInputs = {
    folio: "",
    fechaFactura: "",
    comentarios: "",
    referencia: "",
    total: "",
    moneda: "USD",
    leerXML: 0,
    tipoFactura: "FILE",
    archivos: [],
  };
  const [form, setValues] = useState(initialInputs);
  const [load, setLoading] = useState(false);
  const [isSearching, setSearch] = useState(false);
  const [options, setOptions] = useState([]);
  const [progress, setProgress] = useState({
    step: 0,
    message: "Iniciando",
    posentaje: 0,
  });
  const [referencas, setReferencia] = useState([]);
  const [t] = useTranslation("facturaStore");
  var res = form.archivos;

  const MySwal = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );

  const MySwal2 = withReactContent(Swal);

  useState(() => {
    props.clearEmbarqueRequest();
    if (props.session.user.role === "ADMIN") {
      MySwal2.fire({
        title: t("modal.pregunta"),
        icon: "question",
        input: "select",
        inputOptions: {
          ADMIN: "Administrativa",
          NOMINA: "Nomina",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Factura con CFDI",
        cancelButtonText: "Factura sin CFDI",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        setValues((f) => ({
          ...f,
          tipoFactura: MySwal2.getInput().value,
          leerXML: result.isConfirmed ? 1 : 0,
        }));
      });
    } else if (
      props.session.user.nacional === "NACIONAL" &&
      props.session.user.AA === 1
    ) {
      MySwal2.fire({
        title: t("modal.pregunta"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("modal.opFactura"),
        cancelButtonText: t("modal.opPedimento"),
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        setValues((f) => ({ ...f, leerXML: result.isConfirmed ? 1 : 0 }));
      });
    }
  });

  useEffect(() => {
    let reff = referencas.join(";");
    setValues((f) => ({ ...f, referencia: reff }));
  }, [referencas, setValues]);

  const handleFindProvider = (query) => {
    props
      .fetchProviders(`cw/provee?name=${encodeURIComponent(query)}`,props)
      .then((data) => {
        setOptions(data);
      });
  };

  const handleFindPersonal = (query) => {
    props
      .fetchProviders(
        `ApiEmpleados.json?RFMfind=SELECT%20%2A%20WHERE%20NOMBRE_FORMULA%20LIKE%20%27${encodeURIComponent(
          query
        )}%2A%27`
      )
      .then((data) => {
        setOptions(data.data);
      });
  };

  const handelSelecProve = ([selectedProvider]) => {
    setSearch(true);
    if (selectedProvider !== undefined) {
      setValues({
        ...form,
        idFProveedor: selectedProvider.Code,
        razonSocial: selectedProvider.FullName,
        RFCUser: selectedProvider.RFC
      });
      setSearch(false);
    }
  };

  const handelSelecPersonal = ([selectedProvider]) => {
    setSearch(true);
    if (selectedProvider !== undefined) {
      setValues({
        ...form,
        idFProveedor: selectedProvider["ID_EMPLEADO"],
        razonSocial: selectedProvider["NOMBRE_FORMULA"],
        RFCUser: selectedProvider["RFC"],
        contpaqMxn:selectedProvider["contpaqMxn"],
        contpaqUsd:selectedProvider["contpaqUsd"]
      });
      setSearch(false);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  //------> Gestionando Archivos
  //---> Obteniendo archivos desde el componente DragAndDropList
  const handleCarga = (resBack) => {
    ///Validando espacio
    if (Number(resBack.size) >= 20971520) {
      MySwal.fire({
        icon: "error",
        title: "El peso maximo por archivo es de 20MB",
      });
      return false;
    } else {
      if (res.length > 0) {
        //--> Verificando que la existencia de un xml
        let exist = res.find(
          (x) =>
            x.extension === resBack.extension && resBack.extension === "xml"
        );
        if (exist !== undefined) {
          MySwal.fire({
            icon: "error",
            title: "No es posible cargar mas de un XML",
          });
          return false;
        }
      }
    }

    let archsum =
      form.archivos.reduce((size, obj) => Number(obj.size) + Number(size), 0) +
      resBack.size;
    if (archsum <= 20971520) {
      res = res.concat(resBack);
      setValues({ ...form, archivos: res });
    } else {
      MySwal.fire({
        icon: "error",
        title: "El peso maximo de los archivos es 20MB",
      });
    }
  };

  //---> Eliminando Archivo Seleccionado
  const delteArchivo = (index) => {
    let del = form.archivos.filter((fil) => fil.index !== index);
    setValues({ ...form, archivos: del });
  };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const validateForm = () => {
    let validator = [];
    isEmptyOrSpaces(form.folio) &&
      validator.push({ icon: "error", title: "Falta Folio" });
    isEmptyOrSpaces(form.fechaFactura) &&
      validator.push({ icon: "error", title: "Falta Fecha Factura" });
    isEmptyOrSpaces(form.referencia) &&
      form.tipoFactura === "FILE" &&
      validator.push({ icon: "error", title: "Falta Referencia" });
    if (
      (props.session.user.nacional !== "NACIONAL" ||
        props.session.user.AA === 1) &&
      form.leerXML === 0
    ) {
      form.archivos.length < 1 &&
        validator.push({ title: "Faltan Evidencias" });
    } else {
      let exist = form.archivos.find((item) => item.extension === "xml");
      exist === undefined &&
        validator.push({ icon: "error", title: "Falta CFDI" });
    }
    return validator;
  };

  //-----> Gestinando el Guardado
  const handleSave = () => {
    setValues((f) => ({ ...f, folio: f.folio.trim() }));
    let val = validateForm();
    if (val.length >= 1) {
      MySwal.queue(val);
      return false;
    }
    setLoading(true);
    let currentArchivos = form.archivos;
    let factura = form;
    let maxSteps = currentArchivos.length + 1;
    delete factura.archivos;
    setProgress({
      ...progress,
      step: 1,
      message: "Guardando Factura...",
      posentaje: Number((1 / maxSteps) * 100).toFixed(0),
    });
    axios
      .post(
        `${URL}factura`,
        `json=${encodeURIComponent(JSON.stringify(factura))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.session.token,
          },
        }
      )
      .then(({ data }) => {
        handelUpload(currentArchivos, data, maxSteps);
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: [error][0].response.data.Error,
        });
        setValues({
          ...form,
          archivos: currentArchivos,
        });
        setLoading(false);
      });
  };

  const mulitpleFileOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: props.session.token,
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor(
        ((loaded / 1000) * 100) / (total / 1000) + 1
      );
      setProgress({
        ...progress,
        message: `Guardando Archivos`,
        posentaje: percentage,
      });
    },
  };

  const handelUpload = async (currentArchivos, nfactura) => {
    //Subiendo Los Archivos
    let fromData = new FormData();
    fromData.append("idFactura", nfactura.factura.id);
    currentArchivos.forEach((element) => {
      fromData.append("files[]", element.b64);
    });
    await axios
      .post(`${URL}upar`, fromData, mulitpleFileOptions)
      .then(() => {
        callback();
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: [error][0].response.data.Error,
        });
        setValues({
          ...form,
          archivos: currentArchivos,
        });
        setLoading(false);
      });
  };

  //------> Opcion para cancelar la edicion
  const callback = () => {
    props.handeleClose();
    props.handelHide();
  };

  const validateRreferencia = (newRef) => {
    newRef = newRef.trim();
    let Cwregex = /^[A-Za-z]{3}\d{7}$/;
    let FMregex = /^(MAR|TER|AER)-\d{4}-\d+$/
    if(Cwregex.test(newRef) || FMregex.test(newRef)){
      return true;
    } else {
      MySwal.fire({
        icon: "error",
        title: "Formato de referencia no valido",
      });
      return false;
    }
  };

  const myRef = React.createRef();

  const handleTagblur = () => {
    let tags = myRef.current;
    let nwarr = tags.props.tags;
    let tagString = tags.state.input;
    if (!isEmptyOrSpaces(tagString)) {
      if (tags.props.validator(tagString)) {
        nwarr.push(tagString);
        tags.props.onChange(nwarr);
        tags.state.input = "";
      }
    }
  };

  const Selector = () => {
    switch (form.tipoFactura) {
      case "FILE":
        return (
          <div className="col-12" onBlur={handleTagblur}>
            <label htmlFor="re">{t("factura.referenciaValiton")}</label>
            <ReactTagInput
              ref={myRef}
              name="referencia"
              placeholder={t("factura.nuevaRef")}
              tags={referencas}
              removeOnBackspace={true}
              validator={(value) => validateRreferencia(value.toUpperCase())}
              onChange={(newReferencia) => {
                setReferencia(newReferencia.map((x) => x.toUpperCase()));
              }}
              className="form-control"
            />
          </div>
        );
      case "ADMIN":
        return (
          <div className="col-12">
            <label htmlFor="re">Proveedor:</label>
            <AsyncTypeahead
              isLoading={isSearching}
              id="async-providers"
              minLength={3}
              onSearch={handleFindProvider}
              options={options}
              placeholder={form.razonSocial || "Buscar Proveedor"}
              labelKey={"FullName"}
              onChange={(selectedData) => handelSelecProve(selectedData)}
              renderMenuItemChildren={(options) => (
                <Fragment>
                  <span>{options.FullName}</span>
                </Fragment>
              )}
            />
          </div>
        );
        case "NOMINA":
          return (
            <div className="col-12">
              <label htmlFor="re">Personal:</label>
              <AsyncTypeahead
                isLoading={isSearching}
                id="async-providers"
                minLength={3}
                onSearch={handleFindPersonal}
                options={options}
                placeholder={form.razonSocial || "Buscar Personal"}
                labelKey={"NOMBRE_FORMULA"}
                onChange={(selectedData) => handelSelecPersonal(selectedData)}
                renderMenuItemChildren={(options) => (
                  <Fragment>
                    <span>{options["NOMBRE_FORMULA"]}</span>
                  </Fragment>
                )}
              />
            </div>
          );
      default:
        return(        <div>
          <p>{form.tipoFactura}</p>
        </div>);
    }
  };

  if (load === true) {
    return (
      <div className="modalprincipal">
        <div className="modalcontenido">
          <div className="submodal">
            <div className="submodalTitle">
              <span>{progress.message}...</span>
            </div>
          </div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: progress.posentaje + "%" }}
            >
              {progress.posentaje + "%"}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modalprincipal">
        <div className="submodal">
          <div className="submodalTitle">
            <span>{props.session.user.razonSocial}</span>
          </div>
        </div>
        <div className="modalcontenido">
          <div className="row">
            <div className="col-6">
              <label htmlFor="re">{t("factura.noFactura")}:</label>
              <input
                className="form-control"
                type="text"
                name="folio"
                value={form.folio || ""}
                onChange={handleChange}
                required={true}
              />
            </div>
            <div className="col-6">
              <label htmlFor="re">{t("factura.fechaFactura")}:</label>
              <input
                className="form-control"
                type="date"
                name="fechaFactura"
                value={form.fechaFactura || ""}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          {/* Apartado para los provedores extrangeros y los AA nacionales */}
          {(props.session.user.nacional !== "NACIONAL" ||
            props.session.user.AA === 1) &&
          form.leerXML === 0 ? (
            <div className="row">
              <div className="col-8">
                <label htmlFor="re">{t("factura.montoTotal")}:</label>
                <input
                  type="number"
                  name="total"
                  className="form-control"
                  value={form.total || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <label htmlFor="re">{t("factura.divisa")}</label>
                <select
                  name="moneda"
                  className="form-control"
                  value={form.moneda || ""}
                  onChange={handleChange}
                >
                  <option value="USD">USD</option>
                  <option value="MXN">MXN</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
            </div>
          ) : null}
          <div className="row">
            {
              Selector()
            }
          </div>
          <div className="row">
            <div className="col-12">
              <label htmlFor="re">{t("factura.comentarios")}:</label>
              <textarea
                className="form-control"
                name="comentarios"
                value={form.comentarios || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tituloenvio">
            <p>{t("factura.archivosFactura")}</p>
          </div>
          <div className="row">
            <div className="col-4">
              <DragAndDropSingle accept={".xml"} handleDrop={handleCarga}>
                <span>XML</span>
              </DragAndDropSingle>
            </div>
            <div className="col-4">
              <DragAndDropSingle accept={".pdf"} handleDrop={handleCarga}>
                <span>CFDI-PDF</span>
              </DragAndDropSingle>
            </div>
            <div className="col-4">
              <DragAndDropList handleDrop={handleCarga}>
                <span>{t("factura.soporteEntrega")}</span>
              </DragAndDropList>
            </div>
          </div>
          <div className="tituloenvio">
            <p className="subenvio">{t("factura.soporteEntrega")}</p>
          </div>
          <div className="row envio">
            <div className="col-12">
              <ul className="list-group fileListStore">
                {form.archivos.map((item) => (
                  <li className="list-group-item py-1" key={item.index}>
                    <span className="name">{item.name}</span>
                    <button
                      className="btn btn-default btn-sm float-right remove-item"
                      onClick={() => delteArchivo(item.index)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className=" row botonmodal">
          <div className="col-4">
            <button className="btn btn-link cancelar" onClick={callback}>
            {t("factura.salir")}
            </button>
          </div>
          <div className="col-4"></div>
          <div className="col-4">
            <button className="btn btn-link aceptar" onClick={handleSave}>
            {t("factura.guardar")}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    token: state.token,
    embarque: state.embarque,
    backErrors: state.backErrors,
  };
};

const mapDispathToProps = {
  saveFacturaRequest,
  clearEmbarqueRequest,
  setError,
  fetchProviders,
};

export default connect(mapStateToprops, mapDispathToProps)(FacturaRecord);