import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { Toggle, Modal } from "../utils/modalUtil.jsx";

import withReactContent from "sweetalert2-react-content";
import {
  sendStatusFactura,
  getFactura,
  clearFactura,
  acceptFactura,
  rejectFactura,
  applyPago,
  getTerprofit,
  programarFactura,
  getTipoCambio,
} from "../actions/facturas";

import ProfitViewer from "../components/ProfitViewer";
import TakenXML from "./../components/TakenXML";
import TakenNoXML from "./../components/TakenNoXML";
import DocumentList from "./../components/DocumentList";
import ProfitSelected from "../components/ProfitSelected";
import PagoDialog from "../components/PagoDialog";

import "@pathofdev/react-tag-input/build/index.css";
import "../assets/styles/components/FormModal.css";

const FacturaView = (props) => {
  const id = props.match.params.id;
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const MyToast = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );
  const [Loading, SetLoading] = useState(true);
  const [isFMReference, SetIsFMReference] = useState(false);
  const [LoadConcept, SetLoadConcept] = useState(true);
  useState(() => {
    props.getFactura(id, "factura", props).then((data) => {
      let arrRef = data.Factura.referencia.split(";");
      let FMregex = /^(MAR|TER|AER)-\d{4}-\d+$/;
      if (FMregex.test(arrRef[0])) {
        SetIsFMReference(true);
        SetLoadConcept(false);
      } else {
        props
          .getTerprofit(
            `cw/shipes?shipments=${encodeURIComponent(
              data.Factura.referenciaReal
            )}&folio=${encodeURIComponent(data.Factura.folio)}`,
            props
          )
          .then(() => SetLoadConcept(false));
      }
      SetLoading(false);
    });

    props.clearFactura();
  });

  const rechasaFactura = () => {
    MySwal.fire({
      title: "Enviar Rechazo",
      icon: "question",
      input: "textarea",
      inputPlaceholder: "Motivo",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar",
      inputValidator: (value) => {
        if (!value) {
          return "se necesita un mensaje";
        }
      }, 
    }).then((result) => {
      if (result.value) {
        props
          .rejectFactura(
            result.value,
            props.factura.Factura.id,
            "reject",
            props
          )
          .then(() => {
            cliearLinkFilemaker().then(() => {
              props.clearFactura();
              salirLista();
            });
          });
      }
    });
  };

  const linkFilemaker = () => {
    let res = Object.groupBy(props.profit.data, e => e.referencia);
      props.applyPago(res, "cw/charlines",props);
  };

  const cliearLinkFilemaker = async () => {
    if (props.profit.data.length > 0) {
      let res = Object.groupBy(props.profit.data, e => e.referencia);
      props.applyPago(res, "cw/cancelcharlines",props);
    }
  };

  const resibirFactura = () => {
    let objEnvio = {
      tc: "",
      ref: [...new Set(props.profit.data.map((x) => x.referencia))].join(";"),
    };
    if (props.factura.Factura.moneda === "MXN") {
        objEnvio.tc = 1;
    }else{
      props
      .getTipoCambio(`cw/exchange?exdate=${props.factura.Factura.fechaFactura}`,props)
      .then((data) => {
        objEnvio.tc = Number(data);
      })
      .catch(() => {
        MyToast.fire({
          icon: "error",
          title: "No se encuentra el tipo de cambio",
        });
        return;
      });
    }
    props.acceptFactura(objEnvio, props.factura.Factura.id, "acept", props).then(()=>{
      if(!isFMReference){
        linkFilemaker();
      }
      props.clearFactura();
      salirLista();
    });
  };

  const pagarFactura = (info) => {
    //Escribe en la base de datos
    props.acceptFactura(info, props.factura.Factura.id, "pays", props).then(()=>{
      if(!isFMReference){
        linkFilemaker();
      }
      props.clearFactura();
      salirLista();
    });
  };

  const salirLista = () => {
    props.clearFactura();
    switch (props.session.user.role) {
      case "USER":
        history.push("/facturas");
        break;
      case "ADMIN":
        history.push("/program");
        break;
      case "OPERACIONES":
        history.push("/fdeetals");
        break;
      default:
        history.push("/program");
        break;
    }
  };

  const programar = () => {
    props.programarFactura("prog", id, props).then(() => {
      props.clearFactura();
      salirLista();
    });
  };

  const sumaImportes = (data = [], tipo) => {
    if (tipo === "IVA") {
      let sum = data.reduce((a, b) => {
        return a + b.montoIVA;
      }, 0);
      return sum;
    } else {
      let sum = data.reduce((a, b) => {
        return a + b.montoRET;
      }, 0);
      return sum;
    }
  };

  const botonoera = (role) => {
    switch (role) {
      case "DEVELOPER":
        return (
          <>
            {props.factura.Factura.estatus === "RECIBIDO" && (
              <button className="btn btn-success" onClick={programar}>
                Programar
              </button>
            )}
            {props.factura.Factura.estatus === "PROGRAMADO" && (
              <Toggle
                toggle={(show) => (
                  <button className="btn btn-success" onClick={show}>
                    Pagar
                  </button>
                )}
                content={(hide) => (
                  <Modal>
                    <PagoDialog
                      handelHide={hide}
                      pagar={(args) => pagarFactura(args)}
                    />
                  </Modal>
                )}
              />
            )}
            {props.factura.Factura.estatus === "PENDIENTE" && (
              <button className="btn btn-success" onClick={resibirFactura}>
                Aplicar
              </button>
            )}
          </>
        );
      case "ADMIN":
        return (
          <>
            {props.factura.Factura.estatus === "RECIBIDO" && (
              <button className="btn btn-success" onClick={programar}>
                Programar
              </button>
            )}
            {props.factura.Factura.estatus === "PROGRAMADO" && (
              <Toggle
                toggle={(show) => (
                  <button className="btn btn-success" onClick={show}>
                    Pagar
                  </button>
                )}
                content={(hide) => (
                  <Modal>
                    <PagoDialog
                      handelHide={hide}
                      pagar={(args) => pagarFactura(args)}
                    />
                  </Modal>
                )}
              />
            )}
            {props.factura.Factura.estatus === "PENDIENTE" && (
              <button
                className="btn btn-success"
                onClick={resibirFactura}
                disabled={
                  isFMReference?false:
                  (Number(props.profit.total) !== Number(props.factura.Factura.total))
                }
              >
                Aplicar
              </button>
            )}
          </>
        );
      case "OPERACIONES":
        return (
          <button
            className="btn btn-success"
            onClick={resibirFactura}
            disabled={
              isFMReference?false:
              (Number(props.profit.total) !== Number(props.factura.Factura.total))
            }
          >
            Aplicar
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {Loading === true ? (
      <div className="d-flex flex-column justify-content-center align-items-center w-100" style={{height: "100vh"}}>
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <span>Loading...</span>
      </div>
      ) : (
        <div className="principal">
          <div className="row">
            <div
              className={
                props.factura.Factura.tipoFactura === "FILE"
                  ? "col col-6"
                  : "col col-12"
              }
            >
              {props.factura.DocXML !== undefined &&
              props.factura.DocXML !== null ? (
                <>
                  <TakenXML
                    objXml={props.factura.DocXML}
                    comentarios={props.factura.Factura.comentarios}
                    referencias={props.factura.Factura.referencia}
                  />
                </>
              ) : null}
              {props.factura.DocXML === null ? (
                <TakenNoXML props={props.factura.Factura} />
              ) : null}
              {props.factura.Factura.archivos !== undefined &&
              props.factura.Factura.archivos !== null ? (
                <DocumentList
                  props={props}
                  documentos={props.factura.Factura.archivos}
                  idFactura={id}
                  idFProveedor={props.factura.Factura.idFProveedor}
                />
              ) : null}
            </div>
            {props.factura.Factura.tipoFactura === "FILE" && (
              <div className="col col-6">
                {props.factura.Factura.estatus !== "RECHAZADO" ? (
                  <>
                    <ProfitViewer factura={props.factura} isFM={isFMReference}/>
                    <ProfitSelected isLoading={LoadConcept} />
                  </>
                ) : (
                  <>
                    <h4>Motivos de Rechazo</h4>
                    <textarea
                      className="form-control"
                      name="comentarios"
                      value={
                        props.factura.Factura.motivosRechazo === undefined
                          ? null
                          : props.factura.Factura.motivosRechazo
                      }
                      readOnly
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div className=" row">
            <div className="col col-10 col-md-10 col-lg-10 col-xl-10"></div>
            {props.factura.Factura.tipoFactura === "FILE" && (
              <div className="col col-2 col-md-2 col-lg-2 col-xl-2">
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Subtotal: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      allowNegative={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={
                        props.profit.subTotal !== undefined &&
                        props.profit.subTotal !== null
                          ? props.profit.subTotal
                          : 0
                      }
                    />
                  </span>
                </div>
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Iva: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      allowNegative={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={
                        props.profit.total !== null
                          ? sumaImportes(props.profit.data, "IVA")
                          : 0
                      }
                    />
                  </span>
                </div>
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Retencion: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      allowNegative={true}
                      thousandSeparator={true}
                      prefix={"$"}
                      value={
                        props.profit.total !== null
                          ? sumaImportes(props.profit.data, "RET")
                          : 0
                      }
                    />
                  </span>
                </div>
                <div className="row d-flex justify-content-end">
                  <span style={{ fontWeight: "bold" }}>Total: </span>
                  <span>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator={true}
                      allowNegative={true}
                      prefix={"$"}
                      value={
                        props.profit.total !== undefined &&
                        props.profit.total !== null
                          ? props.profit.total
                          : 0
                      }
                    />
                  </span>
                </div>
              </div>
            )}
            <div className="col col-12">
              {botonoera(props.session.user.role, "RET")}
              {props.factura.Factura.estatus !== "PAGADO" &&
                props.factura.Factura.estatus !== "RECHAZADO" && (
                  <button
                    className="btn btn-secondary"
                    onClick={rechasaFactura}
                  >
                    Rechazar
                  </button>
                )}
              <button className="btn btn-danger" onClick={salirLista}>
                Salir
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    factura: state.factura,
    session: state.session,
    profit: state.profit,
  };
};

const mapDispathToProps = {
  sendStatusFactura,
  getFactura,
  clearFactura,
  acceptFactura,
  rejectFactura,
  applyPago,
  getTerprofit,
  programarFactura,
  getTipoCambio,
};

export default connect(mapStateToprops, mapDispathToProps)(FacturaView);
