import React, { useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { findEmbarque } from "../actions/facturas";
import { applyConcepto } from "../actions/profit";

const ProfitViewer = (props) => {
  //Campos y variables
  const [BRef, SetRef] = useState("");
  const [Conceptos, SetConceptos] = useState();
  const [IsLoading, SetIsLoading] = useState(false);

  const MySwal = withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
  );

  const handleChange = (e) => {
    SetRef(e.target.value.toUpperCase());
  };

  const handleFind = (event) => {
    event.preventDefault();
    let Cwregex = /^[A-Za-z]{3}\d{7}$/;
    if (!Cwregex.test(BRef)) {
      MySwal.fire({
        icon: "error",
        title: "Referencia no valida",
      });
      return;
    }
    SetIsLoading(true);
    props
      .findEmbarque(
        `cw/ship?shipment=${encodeURIComponent(
          BRef
        )}&divisa=${encodeURIComponent(props.factura.Factura.moneda)}`,
        props
      )
      .then((data) => {
        SetConceptos(data);
        SetIsLoading(false);
      });
  };

  const addConepto = (item) => {
    item.facturaProveedor = props.factura.Factura.folio;
    item.fechaPagado = props.factura.Factura.fechaFactura;
    item.idProveedor = props.factura.Factura.idFProveedor;
    props.applyConcepto(item);
  };

  return (
    <div>
      <h4>Buscador de Conceptos</h4>
      <div className="text-center">
        <div className="d-flex justify-content-center">
          {props.isFM ? (
            <div className="alert alert-warning" role="alert">
              La factura contiene referencias de Filemaker, Estas referencias ya
              no pueden ser ligadas.
              <i className="ml-2 fa fa-triangle-exclamation"></i>
            </div>
          ) : (
            <form
              className="form-inline"
              onSubmit={handleFind}
              style={{ marginTop: "10px" }}
            >
              <input
                value={BRef}
                onChange={handleChange}
                className="form-control"
                type="text"
                placeholder="Referencia"
                required
              />
              <button className="btn btn-info goFilter" type="submit">
                <i className="fas fa-search"></i>
              </button>
            </form>
          )}
        </div>
      </div>
      <br />
      <div className="tabla-sm-cal">
        {IsLoading === true ? (
          <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <div className="spinner-grow" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <span>Buscando...</span>
          </div>
        ) : (
          <table className="table table-sm">
            <thead className="titulotabla-sm">
              <tr>
                <th>Cnt</th>
                <th>Concepto</th>
                <th>Divisa</th>
                <th>Monto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Conceptos !== undefined &&
                Conceptos.map((item, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td className="td-sm text-center">{item.cantidad}</td>
                      <td className="td-sm">{item.concepto}</td>
                      <td className="td-sm text-center">{item.divisa}</td>
                      <td className="td-sm text-right">
                        <NumberFormat
                          value={item.monto}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                      <td className="td-sm text-center">
                        <button
                          onClick={() => addConepto(item)}
                          className="btn btn-link btn-sm"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </td>
                    </tr>
                    {item.contenedores !== undefined && (
                      <tr>
                        <td className="th-sm">Info:</td>
                        <td colSpan="3" className="td-sm">
                          {item.contenedores}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    factura: state.factura,
    session: state.session,
  };
};

const mapDispathToProps = {
  findEmbarque,
  applyConcepto,
};

export default connect(mapStateToprops, mapDispathToProps)(ProfitViewer);
