import React, { useState, useEffect, Fragment } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import { fetchProviders } from "../actions/usuarios";
import  { getTipoCambio } from "../actions/facturas";
import NumberFormat from "react-number-format";

const PagoDialog = (props) => {
  const [form, setValues] = useState({
    fechaPago: new Date().toLocaleDateString("fr-CA"),
    idCuenta: "",
    cuenta: "",
    alias: "",
    moneda: "",
    tipoCambio:0
  });
  const [isValid, setValid] = useState(false);
  const [options, setOptions] = useState([]);
  const [isSearching, setSearch] = useState(false);

  const nowTipoCambio = (fechas) =>{
    if(props.factura.moneda !== "MXN"){
          props.getTipoCambio(`cw/exchange?exdate=${fechas}`,props).then((data)=>{
      setValues({
        ...form,
        tipoCambio: Number(data)
      })
    })
    }
  }

  useState(()=>{
    if (props.factura.moneda === "MXN") {
      setValues({
        ...form,
        tipoCambio:1
      })
    }
  })

  useEffect(() => {
    if (form.fechaPago !== null && form.idCuenta.length > 0 && form.tipoCambio > 0  ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [form, isValid]);
  
  useEffect(() => {
    nowTipoCambio(form.fechaPago)
  }, [form.fechaPago]);

  const handleFindBanco = (query) => {
      props.getTipoCambio(`cuentas?filter=${encodeURIComponent(query)}`,props).then((data)=>{
        setOptions(data);
      });
  };
  const handelSelec = ([selectedProvider]) => {
    setSearch(true);
    if (selectedProvider !== undefined) {
      setValues({
        ...form,
        idCuenta: selectedProvider.idCuentaBanco,
        alias: selectedProvider.alias,
        cuenta: selectedProvider.cuenta,
        moneda: selectedProvider.divisa,
      });
      setSearch(false);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...form,
      fechaPago: event.target.value
    });
  };

  const handleChangeTC = (event) => {
    setValues({
      ...form,
      tipoCambio: event.value
    });
  };

  const pagar = () => {
    props.pagar(form);
    props.handelHide();
  };

  const cbHide = () => {
    props.handelHide();
  };

  return (
    <div className="modalprincipal">
      <div className="submodal">
        <div className="submodalTitle">
          <span>INFORMACION DE PAGO</span>
        </div>
      </div>
      <div className="modalcontenido">
        <div className="row justify-content-center">
          <div className="col col-4">
            <label htmlFor="re">Fecha Pago:</label>
            <input
              type="date"
              name="fechaPago"
              value={form.fechaPago}
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="col col-5">
            <label htmlFor="re">Cuenta Bancaria:</label>
            <AsyncTypeahead
              isLoading={isSearching}
              id="async-providers"
              minLength={0}
              onSearch={handleFindBanco}
              options={options}
              placeholder={form.alias || "Buscar Banco"}
              labelKey={"alias"}
              onChange={(selectedData) => handelSelec(selectedData)}
              renderMenuItemChildren={(options) => (
                <Fragment>
                  <span>
                    {options.alias}-{options.divisa}
                  </span>
                </Fragment>
              )}
            />
          </div>
          <div className="col col-3">
            <label htmlFor="re">Tipo Cambio:</label>
            <NumberFormat
              className="form-control"
              displayType="input"
              thousandSeparator={true}
              allowNegative={true}
              value={form.tipoCambio}
              onValueChange={handleChangeTC}
              disabled = {props.factura.moneda === "MXN"}
              prefix={"$"}
            />
          </div>
        </div>
        <hr />
        <div className="row botonmodal">
          <div className="col col-6">
            <button
              className=" btn btn-link aceptar"
              type="button"
              onClick={pagar}
              disabled={!isValid}
            >
              Pagar
            </button>
          </div>
          <div className="col col-6">
            <button
              className=" btn btn-link cancelar"
              type="button"
              onClick={cbHide}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    session: state.session,
    providers: state.providers,
    factura: state.factura.Factura,
  };
};
const mapDispathToProps = {
  fetchProviders,
  getTipoCambio
};

export default connect(mapStateToprops, mapDispathToProps)(PagoDialog);
