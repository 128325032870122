import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { fetchProviders } from "../actions/usuarios";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const FiltroForm = (props) => {
  const [searchForm, setInput] = useState({
    tipo: "p",
    idProveedor: "",
    proveedor: "",
    file: "",
    folio: "",
    ffactura: "",
    moneda: "",
    finc: "",
    status: "",
  });

  const [options, setOptions] = useState([]);
  const [poptions, setPOptions] = useState([]);
  const [isSearching, setSearch] = useState(false);

  const handleFinderChange = (event) => {
    setInput({
      ...searchForm,
      [event.target.name]: event.target.value.toLowerCase(),
    });
  };

  const provRef = React.createRef();
  const perRef = React.createRef();
  const handleSelectChange = (event) => {
    if (searchForm.tipo === "p") {
      provRef.current.clear();
    } else {
      perRef.current.clear();
    }
    setInput({
      ...searchForm,
      idProveedor: "",
      proveedor: "",
      tipo: event.target.value.toLowerCase(),
    });
  };

  const handleFindProvider = (query) => {
    props
      .fetchProviders(`cw/provee?name=${encodeURIComponent(query)}`,props)
      .then((data) => {
        setOptions(data);
      });
  };

  const handelSelec = ([selectedProvider]) => {
    setSearch(true);
    if (selectedProvider !== undefined) {
      setInput({
        ...searchForm,
        idProveedor: selectedProvider.Code,
        proveedor:selectedProvider.FullName,
      });
      setSearch(false);
    } else {
      setInput({
        ...searchForm,
        idProveedor: "",
        proveedor: "",
      });
      setSearch(false);
    }
  };

  const handleFindPersonal = (query) => {
    props
      .fetchProviders(
        `ApiEmpleados.json?RFMfind=SELECT%20%2A%20WHERE%20NOMBRE_FORMULA%20LIKE%20%27${encodeURIComponent(
          query
        )}%2A%27`
      )
      .then((data) => {
        setPOptions(data.data);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleClean = () => {
    if (searchForm.tipo === "p") {
      provRef.current.clear();
    } else {
      perRef.current.clear();
    }
    setInput({
      ...searchForm,
      tipo: "p",
      idProveedor: "",
      proveedor: "",
      file: "",
      folio: "",
      ffactura: "",
      moneda: "",
      finc: "",
      status: "",
    });
  };

  return (
    <div>
      <form className="modalcontenido" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col col-2">
            <label htmlFor="re">Tipo:</label>
            <select
              name="tipo"
              value={searchForm.tipo || ''}
              onChange={handleSelectChange}
              className="form-control"
            >
              <option value="p">Proveedor</option>
              <option value="u">Personal</option>
            </select>
          </div>
          {searchForm.tipo === "p" ? (
            <div className="col col-10">
              <label htmlFor="re">Proveedor:</label>
              <AsyncTypeahead
                ref={provRef}
                isLoading={isSearching}
                id="async-providers"
                minLength={2}
                onSearch={handleFindProvider}
                options={options}
                placeholder="Proveedor"
                labelKey={"FullName"}
                onChange={(selectedData) => handelSelec(selectedData)}
                renderMenuItemChildren={(options) => (
                  <Fragment>
                    <span>{options.FullName}</span>
                  </Fragment>
                )}
              />
            </div>
          ) : (
            <div className="col col-10">
              <label htmlFor="re">Personal:</label>
              <AsyncTypeahead
                ref={perRef}
                isLoading={isSearching}
                id="async-Personal"
                minLength={3}
                onSearch={handleFindPersonal}
                options={poptions}
                placeholder={"Buscar Personal"}
                labelKey={"NOMBRE_FORMULA"}
                onChange={(selectedData) => handelSelec(selectedData)}
                renderMenuItemChildren={(poptions) => (
                  <Fragment>
                    <span>{poptions["NOMBRE_FORMULA"]}</span>
                  </Fragment>
                )}
              />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="col col-4">
            <label htmlFor="re">Folio:</label>
            <input
              value={searchForm.folio  || ''}
              name="folio"
              onChange={handleFinderChange}
              className="form-control"
              type="text"
            />
          </div>
          <div className="col col-4">
            <label htmlFor="re">File:</label>
            <input
              value={searchForm.file  || ''}
              name="file"
              onChange={handleFinderChange}
              className="form-control"
              type="text"
            />
          </div>
          <div className="col col-4">
            <label htmlFor="re">Fecha Factura:</label>
            <input
              value={searchForm.ffactura  || ''}
              name="ffactura"
              onChange={handleFinderChange}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col col-3">
            <label htmlFor="re">Moneda</label>
            <select
              name="moneda"
              value={searchForm.moneda  || ''}
              onChange={handleFinderChange}
              className="form-control"
            >
              <option value="">Todos</option>
              <option value="mxn">MXN</option>
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
            </select>
          </div>
          <div className="col col-3">
            <label htmlFor="re">Financiado</label>
            <select
              name="finc"
              value={searchForm.finc  || ''}
              onChange={handleFinderChange}
              className="form-control"
            >
              <option value="">Todos</option>
              <option value="si">SI</option>
              <option value="no">NO</option>
            </select>
          </div>
          <div className="col col-3">
            <label htmlFor="re">Status</label>
            <select
              name="status"
              value={searchForm.status || ''}
              onChange={handleFinderChange}
              className="form-control"
            >
              <option value="">Todos</option>
              <option value="pendiente">PENDIENTE</option>
              <option value="recibido">RECIBIDO</option>
              <option value="programado">PROGRAMADO</option>
              <option value="pagado">PAGADO</option>
              <option value="rechazado">RECHAZADO</option>
            </select>
          </div>
          <div className="col col-3 d-flex align-items-end ">
            <div>
              <button
                className="btn btn-info"
                type="submit"
                onClick={() => props.ApplyFilter(searchForm)}
              >
                Aplicar Filtro <i className="fa fa-filter"></i>
              </button>
            </div>
            <div>
              <button
                className="btn btn-info ml-1"
                type="submit"
                onClick={handleClean}
              >
                Limpiar Filtro <i className="fa fa-trash-alt"></i>
              </button>
            </div>
            <div>
              <button
                className="btn btn-danger ml-1"
                type="submit"
                onClick={() => props.Close()}
              >
                Cerrar <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
const mapStateToprops = (state) => {
  return {
    token: state.session.token,
  };
};
const mapDispathToProps = {
  fetchProviders,
};

export default connect(mapStateToprops, mapDispathToProps)(FiltroForm);
