import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { URL } from "../utils/globalConect";
import NoData from "./NoData";
import ConceptosFactura from "./ConceptosFactura";

const FacturasCliente = (props) => {
  const [isLoading, setloadin] = useState(false);
  const [facturas, setFacturas] = useState([]);
  const [isToggle, setToggle] = useState(null);

  useState(() => {
    setloadin(true);
    axios
      .get(
        `${URL}cw/getallCfdi?shipment=${props.file}`,
        {
          Authorization: props.token,
        }
      )
      .then(({ data }) => {
        setFacturas(data);
        setloadin(false);
      })
      .catch(() => {
        setloadin(false);
      });
  });

  const handelTogglin = (index) =>{
    if (isToggle === index) {
      setToggle(null)
    }else{
      setToggle(index)
    }
  }

  const handleExit = () => {
    props.handelHide();
  };

  return (
    <div className="modalprincipal">
      <div className="submodal">
        <div className="submodalTitle">
          <span>FACTURAS RELACIONADAS</span>
        </div>
      </div>
      <div className="modalcontenido">
        <div className="row">
          {isLoading === true ? (
            <div className="principal">
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="tabla-sm-cal col-12">
              {facturas && facturas.length > 0 ? (
                <table className="table table-sm">
                  <thead className="titulotabla-sm">
                    <tr>
                      <th>Factura</th>
                      <th>IdDocumento</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {facturas.map((item, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td className="td-sm">{item.FileName}</td>
                          <td className="td-sm">{item.DocumentID}</td>
                          <td>
                            <button
                            style={{border:0,padding:0}}
                              className="btn btn-link btn-sm"
                              onClick={() => handelTogglin(i)}
                            >
                              <i className="far fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                        {isToggle !== null && isToggle === i && (
                          <tr>
                            <td colSpan="5">
                              <div className="col col-12">
                                <ConceptosFactura documentId={item.DocumentID}/>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoData />
              )}
            </div>
          )}
        </div>
        <hr />
        <div className="row botonmodal">
          <div className="col-1">
            <button className="btn btn-danger" onClick={handleExit}>
              Salir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    token: state.session.token,
  };
};

export default connect(mapStateToprops,null)(FacturasCliente);
