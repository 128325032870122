import React, { useState } from "react";
import { connect } from "react-redux";
import { getFactura, clearFactura } from "../actions/facturas";
import DocumentList from "./DocumentList";
import TakenNoXML from "./TakenNoXML";
import TakenXML from "./TakenXML";
import ReactTagInput from "@pathofdev/react-tag-input";
import { useTranslation } from "react-i18next";

const FacturaViewProv = (props) => {
  const [t] = useTranslation("takenXML");
  const [Loading, SetLoading] = useState(true);
  const [myRef, setRef] = useState([]);
  useState(() => {
    props.getFactura(props.idFactura, "factura", props).then((data) => {
      setRef(
        data.Factura.referencia === null ||
        data.Factura.referencia === undefined
        ? []
        : data.Factura.referencia.split(";"))
      SetLoading(false);
    });
    props.clearFactura();
  });
  const cbHide = () => {
    props.handelHide();
  };
  return (
    <div className="modalprincipal">
      {Loading === true ? (
        <div className="modalcontenido">
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="submodal">
            <div className="submodalTitle">
              <span>Factura View</span>
            </div>
          </div>
          <div className="modalcontenido" style={{ padding: "20px" }}>
            <div className="row">
              <div className="col col-12">
                {props.factura.DocXML !== undefined &&
                props.factura.DocXML !== null ? (
                  <>
                    <TakenXML
                      objXml={props.factura.DocXML}
                      comentarios={props.factura.Factura.comentarios}
                    />
                  </>
                ) : null}
                {props.factura.DocXML === null ? (
                  <TakenNoXML props={props.factura.Factura} />
                ) : null}
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="re">
                      {t("cabecera.Vallitonrefrencia")}
                    </label>
                    {myRef !== undefined && (
                      <ReactTagInput
                        name="referencia"
                        readOnly={true}
                        tags={myRef}
                        className="form-control"
                      />
                    )}
                  </div>
                </div>
                {
                  props.factura.Factura.estatus === "RECHAZADO" &&
                  <>
                <br />
                  <h4>Motivos de Rechazo</h4>
                  <textarea
                    className="form-control"
                    name="comentarios"
                    value={
                      props.factura.Factura.motivosRechazo === undefined
                        ? null
                        : props.factura.Factura.motivosRechazo
                    }
                    readOnly
                  />
                </>
                }
                <br />
                {props.factura.Factura.archivos !== undefined &&
                props.factura.Factura.archivos !== null ? (
                  <DocumentList
                    props={props}
                    documentos={props.factura.Factura.archivos}
                    idFactura={props.idFactura}
                    idFProveedor={props.factura.Factura.idFProveedor}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <hr />
          <div className="row botonmodal">
            <div className="col-12">
              <button
                className=" btn btn-link cancelar"
                type="button"
                onClick={cbHide}
              >
                {t("cabecera.salir")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    factura: state.factura,
    session: state.session,
    profit: state.profit,
  };
};

const mapDispathToProps = {
  getFactura,
  clearFactura,
};

export default connect(mapStateToprops, mapDispathToProps)(FacturaViewProv);
