import React from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Toggle, Modal } from "../utils/modalUtil.jsx";

//importando actions
import { deleteConcepto } from "../actions/profit";
import { applyPago } from "../actions/facturas";
import FacturasCliente from "./FacturasCliente.jsx";


const ProfitSelected = (props) => {
  
  const delConcepto = (data) => {
    props.deleteConcepto(data);
  };

  return (
    <div>
      <h4>Conceptos Relacionados</h4>
      {props.isLoading === true?(
        <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <span>Buscando conceptos...</span>
        </div>):(
      <div className="row">
        <div className="tabla-sm-cal col-12">
          <table className="table table-sm">
            <thead className="titulotabla-sm">
              <tr>
                <th>Referencia</th>
                <th>Concepto</th>
                <th>Divisa</th>
                <th>Monto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.profit.data !== undefined &&
                props.profit.data.map((item, i) => (
                  <tr key={i}>
                    <td className="td-sm text-center">{item.referencia}</td>
                    <td className="td-sm">{item.concepto}</td>
                    <td className="td-sm text-center">{item.divisa}</td>
                    <td className="td-sm text-right">
                      <NumberFormat
                        value={item.monto}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                    <td className="td-sm text-center">
                      <button
                        className="btn btn-link btn-sm"
                        onClick={() => delConcepto(item)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                      <Toggle
                        toggle={(show) => (
                          <button className="btn btn-link" onClick={show}>
                            <i className="far fa-eye"></i>
                          </button>
                        )}
                        content={(hide) => (
                          <Modal>
                            <FacturasCliente handelHide={hide} file={item.referencia} />
                          </Modal>
                        )}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      )}
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    profit: state.profit,
    session: state.session,
    factura: state.factura.Factura,
  };
};

const mapDispathToProps = {
  deleteConcepto,
  applyPago,
};

export default connect(mapStateToprops, mapDispathToProps)(ProfitSelected);
