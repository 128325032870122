import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { sendLogin } from "../actions";
import "../assets/styles/components/Login.css";
import logo from "../assets/static/valitoncorp_logo.png";

const Login = (props) => {
  const { backErrors, session } = props;
  const [t, i18n] = useTranslation("login");
  const [form, setValues] = useState({
    username: "",
    password: "",
    lng: session.lng,
  });

  useEffect(() => {
    switch (session.user.role) {
      case "ADMIN":
        props.history.push("/program");
        break;
      case "OPERACIONES":
        props.history.push("/home");
        break;
      case "USER":
        props.history.push("/facturas");
        break;
      default:
        break;
    }
  }, [session.user.role, props.history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.sendLogin(form, "login");
  };

  //Agregando informacion al formulario
  const handleChange = (event) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "lng") {
      i18n.changeLanguage(event.target.value);
    }
  };

  return (
    <div className="container-fluid">
      <div className="contenedor">
        <div>
          <img src={logo} alt="Logo" />
          <hr />
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="form-group">
              <i className="icono izquierda fa fa-globe"></i>
              <select
                name="lng"
                value={form.lng}
                onChange={handleChange}
              >
                <option value="es">Español</option>
                <option value="en">English</option>
              </select>
            </div>
            <div className="form-group">
              <i className="icono izquierda fa fa-user"></i>
              <input
                type="text"
                name="username"
                onChange={handleChange}
                placeholder={t("global.usuario")}
                required
              />
            </div>
            <div className="form-group">
              <i className="icono izquierda fa fa-lock"></i>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                required
                placeholder={t("global.password")}
              />
            </div>
            {backErrors && backErrors.length !== 0 && (
              <div>
                <hr />
                <div className="alert alert-danger text-center" role="alert">
                  <span>{Object.values(backErrors)}</span>
                </div>
              </div>
            )}
            <br />
            <div className="botones">
              <button className="boton" type="submit" value="Login">
                {t("global.login")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    backErrors: state.backErrors,
    session: state.session,
  };
};
const mapDispathToProps = {
  sendLogin,
};

export default connect(mapStateToprops, mapDispathToProps)(Login);
