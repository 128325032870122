import axios from "axios";
import { URL } from "../utils/globalConect";

export const cancelEditor = (payload) => ({
  type: "CANCELEDITUSER_REQUEST",
  payload,
});

export const fetchUsersRequest = (payload) => ({
  type: "FETCHUSERS_REQUEST",
  payload,
});

export const fetchProvidersRequest = (payload) => ({
  type: "FETCHPROVIDERS_REQUEST",
  payload,
});

export const saveUserRequest = (payload) => ({
  type: "SAVEUSER_REQUEST",
  payload,
});

export const updateUserRequest = (payload) => ({
  type: "UPDATEUSER_REQUEST",
  payload,
});

export const deleteUserRequest = (payload) => ({
  type: "DELETEUSER_REQUEST",
  payload,
});

export const fetchUsers = (section, token) => {
  return (dispatch) => {
    axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token,
        },
      })
      .then(function (response) {
        dispatch(fetchUsersRequest(response.data));
      })
      .catch(function (error) {
        dispatch(setError(error));
      });
  };
};

export const fetchProviders = (section, props) => {
  return async () => {
    return axios
      .get(`${URL}${section}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.token,
        },
      })
      .then(({ data }) => {
        return data.response;
      })
      .catch((_) => {});
  };
};

export const saveUser = (payload, section, props) => {
  return async (dispatch) => {
    return axios
      .post(
        `${URL}${section}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.token,
          },
        }
      )
      .then(({ data }) => {
        dispatch(saveUserRequest(data.user));
      })
      .catch(function () {
        throw new Error("No se armo");
      });
  };
};

export const updateUser = (payload, section, props) => {
  return (dispatch) => {
    axios
      .put(
        `${URL}${section}/${props.id}`,
        `json=${encodeURIComponent(JSON.stringify(payload))}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: props.token,
          },
        }
      )
      .then(({ data }) => {
        dispatch(updateUserRequest(data.user));
      })
      .then(() => props.handelHide())
      .catch((_) => {});
  };
};

export const deleteUser = (section, props, id) => {
  return (dispatch) => {
    axios
      .delete(`${URL}${section}/${id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: props.session.token,
        },
      })
      .then(({ data }) => {
        dispatch(deleteUserRequest(data.idd));
      })
      .catch((_) => {});
  };
};

export const setError = (payload) => ({
  type: "SET_ERROR",
  payload,
});
